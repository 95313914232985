import { FC, useCallback, useMemo } from 'react';
import PickListService from '../../../../services/PickListService';
import TemplateFormService from '../../../../services/TemplateFormService';
import ConditionsProperties from '../../../form-builder/ConditionsProperties';
import { EditorProps } from '../../../form-builder/FormBuilderTypes';
import PreviewProperties from '../../../form-builder/PreviewProperties';
import { Option } from '../../../Option';
import DropdownSelect from '../../../shared/form-control/DropdownSelect';
import { ChildFormRenderType } from './ChildFormRenderType';
import { FormType } from '../../../../models/FormTypes';
import { createPickListSource } from '../PickListSource';
import { createFormTemplateSource } from '../FormTemplateSource';
import { AssetTemplateSourceKey } from '../../../../models/Picklist';
import ClientTemplateFormService from '../../../../services/ClientTemplateFormService';
import ActionEditorBase from '../ActionEditorBase';
import { Item } from '../../../shared/form-control/DropdownDefaultComponents';
import { OpenInNewTabValueRendererWrapper } from '../../../shared/form-control/DropdownSharedComponnets';
import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { useCurrentClient, useCurrentTenantId } from '../../../../global-state/Clients';

const ChildFormListEditor: FC<EditorProps> = (props) => {
  const { action, patchData, form, builderBaseUrl } = props;
  const { sourceType, templateFormId, renderType = ChildFormRenderType.External } = action?.data || {};

  const client = useCurrentClient((x) => x.value);
  const currentTenantId = useCurrentTenantId((x) => x.value);

  const { t } = useTranslation(['form-builder', 'organisation']);

  const renderTypes: Option<string, string>[] = [
    { text: ChildFormRenderType.Inline, id: ChildFormRenderType.Inline, value: ChildFormRenderType.Inline },
    { text: ChildFormRenderType.External, id: ChildFormRenderType.External, value: ChildFormRenderType.Inline },
  ];

  const templateService = useMemo(() => {
    if (client) {
      return new ClientTemplateFormService(client.id);
    }
    return TemplateFormService;
  }, [client]);

  const {
    data: templates = [],
    refetch: refetchTemplates,
    isFetching: isFetchingTemplates,
  } = useQuery({
    queryKey: ['form-templates', action.id],
    queryFn: () =>
      templateService
        .getAllTemplates({
          includeArchived: true,
          latestOnly: true,
          types: [FormType.Asset, FormType.SubForm, FormType.SubFormWithApproval],
        })
        .then((res) => {
          return res.data.filter((template) => template.id !== form.id);
        }),
  });

  const {
    data: picklists = [],
    refetch: refetchPicklists,
    isFetching: isFetchingPicklists,
  } = useQuery({
    queryKey: ['picklists', action.id],
    queryFn: () =>
      PickListService.getAll({ pageSize: 500, pageNumber: 1, clientId: client?.id, includeArchived: true }).then((res) => {
        return res.data;
      }),
  });

  const templateForms = useMemo(() => {
    return createFormTemplateSource(templates);
  }, [templates]);

  const picklistSources = useMemo(() => {
    return [
      {
        id: 'input',
        value: 'input',
        text: t('form-builder:action-properties.subform.text-input'),
      },
      ...createPickListSource(picklists, t),
      ...createFormTemplateSource(templates.filter((template) => template.type === FormType.Asset)).map((group) => ({
        ...group,
        options: group.options.map((x) => ({ ...x, id: `${AssetTemplateSourceKey}${x.id}` })),
      })),
    ];
  }, [picklists, t, templates]);

  const selectedTemplateForm: Item | undefined = useMemo(
    () =>
      templateForms
        .map((x) => ('options' in x ? x.options : x))
        .flat()
        .find((x) => (x as Item).id === templateFormId) as Item,
    [templateFormId, templateForms],
  );

  const onSourceTypeChanged = (option: Item) => {
    patchData({ sourceType: option.id });
  };

  const onTemplateChanged = (option: Item) => {
    // TEMP: store undefined as version so it always provisions the latest version
    patchData({ templateFormId: option.id, templateFormVersion: undefined }); // option.value });
  };

  const onRenderTypeChanged = (option: Item) => {
    patchData({ renderType: option.id });
  };

  const selectedPicklistSource: Item | undefined = useMemo(
    () =>
      picklistSources
        .map((x) => ('options' in x ? x.options : x))
        .flat()
        .find((x) => x.id === sourceType),
    [picklistSources, sourceType],
  );

  const formLinkProvider = useCallback(
    (item: Item) => {
      return `${builderBaseUrl}/${item.id}/${item.value}`;
    },
    [builderBaseUrl],
  );

  const picklistLinkProvider = useCallback(
    (item: Item) => {
      if (item.id === 'text') {
        return '';
      }

      if (typeof item.value === 'number') {
        return formLinkProvider({ ...item, id: item.id.replace(AssetTemplateSourceKey, '') });
      }

      return item.value ? `/clients/${item.value}/picklists/${item.id}` : `/builder/${currentTenantId}/picklists/${item.id}`;
    },
    [formLinkProvider, currentTenantId],
  );

  return (
    <div data-cy="child-form-list-editor">
      <ActionEditorBase {...props} questionKey="title">
        <DropdownSelect
          label={t('form-builder:action-properties.subform.picklist-source')}
          data-cy="source"
          placeholder={t('form-builder:action-properties.subform.picklist-source')}
          value={{
            id: sourceType,
            text: selectedPicklistSource?.text || '',
            value: selectedPicklistSource?.value || '',
            disabled: selectedPicklistSource?.disabled,
          }}
          options={picklistSources}
          onChange={onSourceTypeChanged}
          isFetching={isFetchingPicklists || isFetchingTemplates}
          onOpen={() => {
            refetchTemplates();
            refetchPicklists();
          }}
          searchable
          customListRenderer={OpenInNewTabValueRendererWrapper(picklistLinkProvider)}
        />

        <DropdownSelect
          label={t('form-builder:action-properties.subform.template-form')}
          data-cy="template-form"
          placeholder={t('form-builder:action-properties.subform.template-form')}
          value={{
            id: templateFormId,
            text: selectedTemplateForm?.text || '',
            value: selectedTemplateForm?.value as number,
            disabled: selectedTemplateForm?.disabled,
          }}
          options={templateForms}
          onChange={onTemplateChanged}
          searchable
          customListRenderer={OpenInNewTabValueRendererWrapper(formLinkProvider)}
          isFetching={isFetchingTemplates}
          onOpen={refetchTemplates}
        />

        <DropdownSelect
          label={t('form-builder:action-properties.subform.render-mode')}
          data-cy="render-mode"
          placeholder={t('form-builder:action-properties.subform.render-mode')}
          value={{ id: renderType, text: renderType, value: renderType }}
          options={renderTypes}
          onChange={onRenderTypeChanged}
        />
      </ActionEditorBase>

      <ConditionsProperties {...props} />

      <PreviewProperties {...props} enableDocumentNumbering />
    </div>
  );
};

export default ChildFormListEditor;
